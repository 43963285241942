































































































































































































































































































































































































































































import { Component, Watch, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import { getComponent } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import DataLoading from '@/lib/mixins/dataLoading';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import Multiselect from 'vue-multiselect';
import { ALL_TASQS_LIST_ITEM, SHOW_ALERT, TASQ_OFF_TARGET_TYPE } from '@/lib/constants';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import setpointV2Module from '@/store/modules/setpointV2Module';
import proceduresModule from '@/store/modules/proceduresModule';
import workflowModule from '@/store/modules/workflowModule';
import isOnline from 'is-online';


@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqSignal: () => getComponent('tasqs/TasqSignal'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    TasqFeedback: () => getComponent('tasqs/TasqFeedbackV3'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    TasqItem: () => getComponent('tasqs/TasqItem'),
    Multiselect,
  },
})
export default class TasqsMobile extends mixins(DataLoading) {

   @Prop({ type: Boolean, required: false, default: false }) hideTop?: boolean;

  tasqOptionsOpen = false;

  chartsLoading = false;

  signalChartTime = 14;

  loadingTasqs = false;

  showComment = false;

  activeTasqTab = 'Signals'

  comment = '';

  visiblePadWellsFrom = 0

  visiblePadWellsTo = 5

  showActionDialog: boolean = false;

  action: any = {
    type: '',
    title: '',
    confirmText: '',
    reason: [],
    person: '',
    comment: '',
    date: '',
    well: '',
    jobType: '',
    frequency: '',
    delayedDays: 0,
  };

  chartTimes: any[] = this.$getConst('CHART_TIMES');

  padWellsSkipToEnd() {
	  this.visiblePadWellsFrom = (Math.ceil(this.activeTasq?.wells.length / 6) - 1) * 6;
	  this.visiblePadWellsTo = this.visiblePadWellsFrom + 5;
  }

  padWellsNext() {
	  if (this.visiblePadWellsTo >= this.activeTasq?.wells.length || this.visiblePadWellsFrom + 6 >= this.activeTasq?.wells.length) {
		  return;
	  }
	  this.visiblePadWellsFrom += 6;
	  this.visiblePadWellsTo += 6;
  }

  padWellsPrevious() {
	  if (this.visiblePadWellsFrom - 6 < 0) {
		  return;
	  }
	  this.visiblePadWellsFrom -= 6;
	  this.visiblePadWellsTo -= 6;
  }

  padWellsSkipToBeginning() {
    this.visiblePadWellsFrom = 0;
    this.visiblePadWellsTo = 5;
  }

  //   getPadWellPaginationElement() {
  // 	  return '<div></div>'
  //   }

  getWells() {
    const responseData: any[] = [];
	  if (this.activeTasq != null) {
		  // @ts-ignore
		  for (let r = 0; r < this.activeTasq.wells.length; r++) {
			  if (r < this.visiblePadWellsFrom) {
				  continue;
			  }
			  if (r > this.visiblePadWellsTo) {
				  continue;
			  }
			  // @ts-ignore
        //   if (this.activeTasq.wells[r].predictionType != "Producing") {

				  // @ts-ignore
				  responseData.push(this.activeTasq.wells[r]);
        //   }
		  }
		  return responseData;
	  }
	  return responseData;
    //   if (this.showAllWells) {
    // 	  // @ts-ignore
    // 	  return this.tasq.wells
    //   } else {

    //   }
  }

  get jobTypeOptions() {
    return [
      { key: 'Install', value: 'Install', id: 0 },
      { key: 'Install', value: 'Install Artificial Lift', id: 1 },
      { key: 'Install', value: 'Install Compressor ', id: 2 },
      { key: 'Site Check', value: 'Site Check', id: 3 },
      { key: 'Site Check', value: 'Dump Checks', id: 4 },
      { key: 'Site Check', value: 'Facility walkdown', id: 5 },
      { key: 'Site Check', value: 'Drain pots and drips', id: 6 },
      { key: 'Site Check', value: 'Drain tank bottoms', id: 7 },
      { key: 'Well Issue', value: 'Well Issue', id: 8 },
      { key: 'Label', value: 'Label', id: 9 },
      { key: 'LOTO', value: 'LOTO', id: 10 },
      { key: 'Regulatory', value: 'Regulatory', id: 11 },
      { key: 'Preventive Maintenance', value: 'Preventive Maintenance', id: 12 },
      { key: 'Preventive Maintenance', value: 'Facility Winterization', id: 13 },
      { key: 'Preventive Maintenance', value: 'Plunger Inspection', id: 14 },
      { key: 'Gauge', value: 'Gauge', id: 15 },
      { key: 'Contractor Jobs', value: 'Contractor Jobs', id: 16 },
      { key: 'Contractor Jobs', value: 'Hot Oiling', id: 17 },
    ].sort((a, b) => a.value.localeCompare(b.value));
  }

  get tasqListLevel() {
	  if (this.activeTasq == null) {
		  return 'WELL';
	  }
	  return this.activeTasq?.level;
  }

  get isProductionDataLoaded() {
    return tasqProductionDataChartModule.productionData.loaded;
  }

  get currentWellType(): any {
	  return this.$route.query.type;
  }

  get signalDescriptions(): any {
	  return tasqSignalsModule.signalDescriptions;
  }

  get currentSignals(): any {
    if (this.chartsLoading) {
      return [];
    }
    const results: any[] = [];
    for (let x = 0; x < this.signalDescriptions.length; x++) {
      for (let y = 0; y < tasqSignalsModule.currentSignals.length; y++) {
        if (tasqSignalsModule.currentSignals[y].name == this.signalDescriptions[x]) {
          results.push(tasqSignalsModule.currentSignals[y]);
        }
      }
    }
    // signalDescriptions
    return results;
  }

  get activeTasq() {
    if (this.kanbanTasq) {
      return tasqsListModule.activeTasq ?  tasqsListModule.activeTasq : assetsModule.activeTasq;
    }
    if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level == 'PAD') {
      return tasqsListModule.activeTasq;
    }
    return assetsModule.activeTasq;
  }

  get reassignUsers() {
    return accountModule.reassignmentList.map((u) => ({
      text: u.name,
      value: u.email,
    }));
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enabledWellsMapping() {
    return assetsModule.enabledWells.map((well) => ({ text: well.text, value: well.value }));
  }

  async created() {
    this.fetchTasqAsync();
  }

  get kanbanTasq(){
    return tasqsListModule.kanbanSelectedTasq
  }

  async fetchTasqAsync() {
    assetsModule.getEnabledWells();
    if (this.kanbanTasq) {
      await this.fetchTasq();
    }
  }

  beforeDestroy() {
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setKanbanSelectedTasq('')
  }

  prepareAction(action) {
    this.showActionDialog = true;
    this.action = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      well: '',
      jobType: '',
      frequency: '',
      delayedDays: 0,
    };
    switch (action) {
      case ('reject'):
        this.action.type = action;
        this.action.title = `Are you sure you want to reject ${this.activeTasq?.wellName}?`;
        this.action.confirmText = 'Yes, reject it';
        this.$dialog.show('actionModal');
        break;
      case ('reassign'):
        this.action.type = action;
        this.action.title = `Select the user to reassign ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Reassign tasq';
        this.$dialog.show('actionModal');
        break;
      case ('wait'):
        this.action.type = action;
        this.action.title = `Please select status for ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Update';
        this.$dialog.show('actionModal');
        break;
      case ('add'):
        this.action.type = action;
        this.action.title = '';
        this.action.confirmText = 'Add tasq';
        this.action.person = { value: accountModule.user.email, text: `${accountModule.user.firstName} ${accountModule.user.lastName}` };
        this.$dialog.show('actionModal');
        break;
      default:
        break;
    }
  }

  async onActionDialogClosed(response) {
    let successText = 'Success'!;

    this.startDataLoading();
    if (response.isOk) {
      switch (this.action.type) {
        case ('reassign'):
          successText = 'Successfully reassigned!';
          await tasqActionsModule.reassignTasq(this.action);
           tasqsListModule.resetSetActiveTasq();
          break;
        case ('reject'):
          successText = 'Successfully Rejected!';
          await tasqActionsModule.rejectTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('wait'):
          successText = 'Successfully added to waitlist!';
          await tasqActionsModule.updateTasqStatus(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('add'):
          successText = 'Tasq added successfully!';
          await tasqActionsModule.addTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        default:
          break;
      }
    }
    await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    this.action = {};
    this.showActionDialog = false;
    this.stopDataLoading();
    this.$eventBus.$emit(SHOW_ALERT, successText);
  }

  async changeChartTimes(val) {
    this.signalChartTime = val;
    tasqsListModule.setSignalChartTime(val);
    // tasqsListModule.signalChartTime = val;
    this.chartsLoading = true;

    const promises: any[] = [];
    promises.push(tasqSignalsModule.getSignalsForTasq(val));
    promises.push(tasqProductionDataChartModule.getProductionData(val));
    promises.push(tasqProductionDataChartModule.getWellHistory(val));
    promises.push(defermentLabelingModule.getChartLabelingData(this.signalChartTime));

    Promise.all(promises).then(() => {
      this.chartsLoading = false;
    }, (err) => {
      // error occurred

    });
  }

  async fetchTasq() {
    this.chartsLoading = true;
    this.chartTimes = this.$getConst('CHART_TIMES');
    let isProducing = tasqsListModule.activeTasq ?  false : true
    tasqProductionDataChartModule.resetProductionDataDefermentLabeling();
    if (this.signalChartTime > 45) {
      this.signalChartTime = 14;
    }
    if (this.kanbanTasq) {
     if(isProducing){
        await assetsModule.setActiveProducingTasq(this.kanbanTasq);
     }else {
        if (tasqsListModule.activeTasq == null) {
		  await tasqsListModule.getTasq({
			  PredictionID: this.kanbanTasq,
		  });
	  }
     }
      await tasqsListModule.setActiveTasq(this.kanbanTasq);
	 
    }

     if(this.activeTasq && this.activeTasq?.overriddenName.toLowerCase() === 'real-time'){
      this.signalChartTime = 5
    }else{
      this.signalChartTime = 14;
    }

    const promises: any[] = [];
    promises.push(tasqSignalsModule.getSignalsForTasq(this.signalChartTime));
    promises.push(tasqProductionDataChartModule.getProductionData(this.signalChartTime));
    // promises.push(tasqProductionDataChartModule.getWellHistory(this.signalChartTime));
    promises.push(defermentLabelingModule.getChartLabelingData(this.signalChartTime));
    if (this.activeTasq != null && (this.currentWellType != 'producing')  && !isProducing) {
      tasqsListModule.getTasqEventHistoryList(this.activeTasq?.workflowTasqId);
      workflowModule.getWellHistory(this.activeTasq?.wellName);
      //   setpointV2Module.getSetpointRecV2ForTasq({wellName: this.activeTasq?.wellName})
	 if (this.currentWellType != 'producing') {

		  workflowModule.getJobResponse(this.activeTasq?.id);
		//   await workflowModule.getJobResponseV2(this.activeTasq?.id);
		  if (workflowModule.v2ResponseData != null) {
			  
			  proceduresModule.getProceduresStepsJson({
				  jsonVersion: workflowModule.v2ResponseData.jsonVersion
			  })
		  } else {
			  proceduresModule.getProceduresStepsJson({})
		  }
		  
	  }
    } else {
      workflowModule.getWellHistory(this.activeTasq?.wellName);
    }
    Promise.all(promises).then(() => {
      tasqsListModule.setSignalChartTime(this.signalChartTime);
      if (this.activeTasq?.engineerType === TASQ_OFF_TARGET_TYPE) {
        let jobOriginExists = false;
        for (let t = 0; t < this.chartTimes.length; t++) {
          if (this.chartTimes[t].text == 'Job Origin') {
            jobOriginExists = true;
          }
        }
        if (!jobOriginExists) {
          this.chartTimes = [{
            id: 5,
            val: this.activeTasq.dateAgoValue + 30,
            text: 'Job Origin',
            additionalClass: 'chart-time--narrow',
          }, ...this.chartTimes];
        }
      }
      this.chartsLoading = false;
    }, (err) => {
      console.log('Error:');
      console.log(err);
      // error occurred
    });
  }

   setActiveTasqDetailTab(tabName) {
    this.activeTasqTab = tabName;
  }

  clearDetails(){
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setKanbanSelectedTasq('');
      console.log('close')
      this.$emit('close-dialog')
  }

  @Watch('kanbanTasq')
  async onkanbanTasqChange(id: string) {
    console.log(id)
    if (id) {
      await this.fetchTasq();
    } else {
      tasqsListModule.setActiveTasq('');
    }
  }
}
